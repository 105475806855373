import { Menu } from 'src/app/interfaces/menu'
import { Injectable } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { ConstantsService } from 'src/app/services/constants.service'
import { apis } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'

@Injectable({
    providedIn: 'root'
})
export class ParallelSidebarService {
    toggled = false
    _hasBackgroundImage = true
    completeMenu: Array<Menu> = []
    userMenu: Array<Menu> = []
    userId: number

    constructor(public api: ApiService, public http: HttpClient, private router: Router) {
        const userString: string = localStorage.getItem('user') as string
        this.userId = JSON.parse(userString)?.id
        this.completeMenu = [
            {
                title: 'Dashboard',
                link: 'dashboard',
                icon: 'fa fa-tachometer-alt',
                active: true,
                type: 'simple'
            },
            {
                title: 'Inspections',
                link: 'inspection',
                icon: 'fa-solid fa-book-open-reader',
                active: true,
                type: 'simple'
            },
            {
                title: 'Calendar',
                link: 'calendar',
                icon: 'fa-solid fa-calendar-days',
                active: true,
                type: 'simple'
            },
            {
                title: 'Transactions',
                link: 'transactions',
                icon: 'fa-solid fa-money-check-dollar',
                active: true,
                type: 'simple'
            },
            {
                title: 'Change Password',
                link: 'change-password',
                icon: 'fas fa-key',
                active: true,
                type: 'simple'
            },
            {
                title: 'Update Profile',
                link: 'update-profile',
                icon: 'fa-regular fa-pen-to-square',
                active: true,
                type: 'simple'
            }
        ]
        // menu

        if (this.api.checkRole()) {
            return
        }
        const permissions = this.api.user.UserPermissions
        this.api.user.userroles.forEach((userRole) => {
            userRole.role.permissions.forEach((p: any) => {
                permissions.push(p)
            })
        })

        this.completeMenu.forEach((menuItem: any) => {
            if (menuItem.type === 'simple') {
                const index = permissions.findIndex(
                    (item: any) => menuItem.permissions.indexOf(item.name) > -1
                )
                if (index > -1) {
                    this.userMenu.push(menuItem)
                }
            }
            if (menuItem.type === 'dropdown') {
                const submenu: Array<any> = []
                menuItem.submenus.forEach((subMenuItem: any) => {
                    const index = permissions.findIndex(
                        (item: any) => subMenuItem.permissions.indexOf(item.name) > -1
                    )
                    if (index > -1) {
                        submenu.push(subMenuItem)
                    }
                })
                if (submenu.length > 0) {
                    menuItem.submenus = submenu
                    this.userMenu.push(menuItem)
                }
            }
        })
    }

    toggle() {
        this.toggled = !this.toggled
    }

    getSidebarState() {
        return this.toggled
    }

    setSidebarState(state: boolean) {
        this.toggled = state
    }

    getMenuList() {
        if (this.api.user.userType === ConstantsService.PARALLEL) {
            return this.completeMenu
        }
        return this.userMenu
    }

    get hasBackgroundImage() {
        return this._hasBackgroundImage
    }

    set hasBackgroundImage(hasBackgroundImage) {
        this._hasBackgroundImage = hasBackgroundImage
    }

    checkRoutePermission(route: string): boolean {
        let permissions: any = []
        this.completeMenu.forEach((menuItem: any) => {
            if (menuItem.type === 'dropdown') {
                const index = menuItem.submenus.findIndex(
                    (submenuItem: any) => submenuItem.link === route
                )
                if (index > -1) {
                    permissions = menuItem.submenus[index].permissions
                    return
                }
            } else {
                if (menuItem.link === route) {
                    permissions = menuItem.permissions
                    return
                }
            }
        })

        return this.api.checkPermissions(permissions)
    }

    getNotificationCount(): Observable<any> {
        const url = `${apis.baseUrl}/admin/notification/count`
        return this.http.get<any>(url, {})
    }
}
