import { PermissionsGuard } from '../auth/permissions-guard'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
// import { AdminGuard } from '../auth/admin-guard'
import { ParallelGuard } from '../auth/parallel-guard'
import { ParallelPanelComponent } from './parallel-panel.component'
import { NotAuthorizedComponent } from './parallel-shared/not-authorized/not-authorized.component'

const routes: Routes = [
    {
        path: '',
        component: ParallelPanelComponent,
        canActivate: [ParallelGuard],
        children: [
            {
                path: 'not-authorized',
                component: NotAuthorizedComponent
            },
            {
                path: 'samples',
                data: { permissions: [''] },
                loadChildren: () =>
                    import('../general-modules/documentation/documentation.module').then(
                        (mod) => mod.DocumentationModule
                    )
            }
        ]
    },
    {
        path: '',
        component: ParallelPanelComponent,
        canActivate: [ParallelGuard],
        children: [
            {
                path: 'dashboard',
                canActivate: [ParallelGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./dashboard/dashboard.module').then((mod) => mod.DashboardModule)
            },
            {
                path: 'not-authorized',
                component: NotAuthorizedComponent
            },
            {
                path: 'change-password',
                canActivate: [ParallelGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./change-password/change-password.module').then(
                        (mod) => mod.ChangePasswordModule
                    )
            },
            {
                path: 'update-profile',
                canActivate: [ParallelGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./update-profile/update-profile.module').then(
                        (mod) => mod.UpdateProfileModule
                    )
            },
            {
                path: 'inspection',
                canActivate: [ParallelGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./inspection/inspection.module').then((mod) => mod.InspectionModule)
            },
            {
                path: 'calendar',
                canActivate: [ParallelGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./calendar/calendar.module').then((mod) => mod.CalendarModule)
            },
            {
                path: 'transactions',
                canActivate: [ParallelGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./transactions/transactions.module').then(
                        (mod) => mod.TransactionsModule
                    )
            }
        ]
    },
    {
        path : 'payment/:status',
        canActivate: [ParallelGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./payments/payment.module').then(
                        (mod) => mod.PaymentModule
                    )
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ParallelPanelRoutes {}
