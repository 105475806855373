import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ParallelPanelComponent } from './parallel-panel.component'
import { SharedModule } from '../website/shared/shared.module'
import { ParallelPanelRoutes } from './parallel-panel.routing'
import { ParallelSharedModule } from './parallel-shared/parallel-shared.module'
import { DataService } from './data.service'

@NgModule({
    declarations: [ParallelPanelComponent],
    imports: [CommonModule, SharedModule, ParallelPanelRoutes, ParallelSharedModule],
    providers: [DataService]
})
export class ParallelPanelModule { }
