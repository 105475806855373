import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxMaskModule } from 'ngx-mask';
import { IAlertsModule } from 'src/app/libs/ialert/ialerts.module';
import { IPaginationModule } from 'src/app/libs/ipagination/ipagination.module';
import { SharedModule } from 'src/app/website/shared/shared.module';
import { BreadcrumsComponent } from './breadcrums/breadcrums.component';
import { LoaderComponent } from './loader/loader.component';
import { NoDataComponent } from './no-data/no-data.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { SkeletonTabelLoaderComponent } from './skeleton-tabel-loader/skeleton-tabel-loader.component';
import { ParallelSidebarComponent } from './parallel-sidebar/parallel-sidebar.component';

@NgModule({
  imports: [
    IAlertsModule,
    BsDropdownModule.forRoot(),
    CommonModule, FormsModule, RouterModule,
    SharedModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    ParallelSidebarComponent,
    SkeletonTabelLoaderComponent,
    LoaderComponent,
    NoDataComponent,
    NotAuthorizedComponent,
    BreadcrumsComponent
  ],
  providers: [
  ],
  exports: [
    ParallelSidebarComponent, LoaderComponent, NoDataComponent, IPaginationModule,
    IAlertsModule, BsDropdownModule, SkeletonTabelLoaderComponent, BreadcrumsComponent,
    CommonModule, FormsModule, RouterModule, NgxMaskModule
  ]
})
export class ParallelSharedModule { }
