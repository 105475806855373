import { Component, OnInit } from '@angular/core'
import { ParallelSidebarService } from '../parallel-panel/parallel-shared/parallel-sidebar/parallel-sidebar.service'
import { ApiService } from '../services/api.service'
import { IAlertService } from '../libs/ialert/ialerts.service'
import { DataService } from './data.service'

@Component({
    selector: 'app-parallel-panel',
    templateUrl: './parallel-panel.component.html',
    styleUrls: ['./parallel-panel.component.scss']
})
export class ParallelPanelComponent implements OnInit {
    parallelName: any
    myBalance:any
    lastName: any
    filters: any = {
        startDate: '',
        endDate: '',
        page: 1
    }
    constructor(
        public sidebarservice: ParallelSidebarService,
        public api: ApiService,
        public ds: DataService,
        private alert: IAlertService
    ) {
        // code here
        this.ds.getPaymentDetail().subscribe((resp) => {
            if (resp.success) {
                api.singleDayInspectionAmount = resp.data.singleDayInspectionAmount
            } else {
                this.alert.error(resp.errors.general)
            }
        })

        this.parallelName = this.api.user.parallel.name
        const params = { ...this.filters }

        this.ds.getMyWallet(params).subscribe((resp) => {
            if (resp.success) {
                //this.api.balance = resp.data.currentBalance
                this.api.balance.next(resp.data.currentBalance)
            } else {
                this.alert.error(resp.errors.general)
            }
        })

       
    }

    ngOnInit() {

        this.api.balance.subscribe((e:any) => {
            this.myBalance = e
        })
    }

    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState())
    }
    toggleBackgroundImage() {
        this.sidebarservice.hasBackgroundImage = !this.sidebarservice.hasBackgroundImage
    }
    getSideBarState() {
        return this.sidebarservice.getSidebarState()
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true)
    }
    logOut(): void {
        const check = this.api.logOut()
        if (check) {
            window.location.href = '/login'
        }
    }
}
